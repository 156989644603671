import React, { useEffect, useState } from "react";

import useModal from "../../Modal/useModal";
import { ITeamFields } from "../types";
import AboutUsPopup from "./AboutUsPopup";

export interface ReturnType {
  showAboutUsPopup: (aboutUsData: ITeamFields) => void;
}

export interface AboutUsPopupProps {
  onVerificationDeclined?: () => void;
}

const useAboutUsPopup = ({
  onVerificationDeclined,
}: AboutUsPopupProps): ReturnType => {
  const [aboutUsContent, setAboutUsContent] = useState({} as ITeamFields);
  const [showPopup, setShowPopup] = useState(false);

  const showAboutUsPopup = (aboutUsData: ITeamFields) => {
    setShowPopup(true);
    setAboutUsContent(aboutUsData);
  };

  useEffect(() => {
    if (showPopup) {
      showAboutUs();
    }
    return () => setShowPopup(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPopup]);

  const [showAboutUs] = useModal(
    <AboutUsPopup
      onDismiss={onVerificationDeclined}
      aboutUsData={aboutUsContent}
    />
  );
  return { showAboutUsPopup };
};

export default useAboutUsPopup;
