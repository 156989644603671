export enum DepartmentName {
  BOARD = "Board",
  DELIVERY = "Delivery",
  BACKEND = "Back-end",
  FRONTEND = "Front-end",
  UNITY = "Unity",
  QA = "QA",
  UXUI = "UX/UI",
  DESIGN = "Design",
  MARKETING = "Marketing",
  BIZDEV = "Biz dev",
  ADVISOR = "Advisor",
  OTHER = "Other",
}

export const getDepartmentColor = (department?: string): string => {
  switch (department) {
    case DepartmentName.BOARD:
      return "#53C8D3";
    case DepartmentName.FRONTEND:
    case DepartmentName.BACKEND:
    case DepartmentName.DELIVERY:
    case DepartmentName.QA:
    case DepartmentName.BIZDEV:
    case DepartmentName.ADVISOR:
    case DepartmentName.UNITY:
      return "#5E93E1";
    case DepartmentName.DESIGN:
    case DepartmentName.UXUI:
      return "#E75685";

    case DepartmentName.MARKETING:
      return "#7763F6";

    default:
      return "#AEAEAE";
  }
};
