import React from "react";
import styled from "styled-components";
import BioContentful from "../../../utils/bioContentful";
import Flex from "../../Box/Flex";
import Modal from "../../Modal/Modal";

import { Text } from "../../Text";
import { ITeamFields } from "../types";

const MainWrapper = styled(Flex)`
  padding: 0 54px 0 54px;
  width: max-content;
  gap: 70px;
  @media (max-width: 960px) {
    flex-direction: column;
    padding: 0 24px 0 24px;
    width: 100%;
    height: 500px;
    gap: 32px;
    justify-content: center;
    align-items: center;
  }
`;

const PersonDetailsWrapper = styled(Flex)`
  max-width: 220px;
  flex-direction: column;

  justify-content: flex-start;
  @media (max-width: 960px) {
    width: 100%;
  }
`;

const BioWrapper = styled.div`
  display: flex;
  width: 580px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  @media (max-width: 960px) {
    width: 100%;
    height: auto;
  }
`;

interface TestimonialPopupProps {
  aboutUsData: ITeamFields;
  onDismiss?: () => void;
}

const TestimonialPopup: React.FC<TestimonialPopupProps> = ({
  onDismiss,
  aboutUsData,
}) => {
  const { personImage, personName, personPosition, personBio } = aboutUsData;

  return (
    <Modal onDismiss={onDismiss}>
      <MainWrapper>
        <PersonDetailsWrapper
          flexDirection="column"
          justifyContent="flex-start"
        >
          <img src={`https:${personImage}`} alt="imageProfile" />
          <Text fontWeight="bold" fontSize="20px" pt="24px" color="#fff">
            {personName}
          </Text>
          <Text fontSize="16px" color="#CAD4E2">
            {personPosition}
          </Text>
        </PersonDetailsWrapper>
        {personBio && (
          <BioWrapper>
            <Text padding="12px 12px 0 0" fontSize="16px" color="#E5EAF0">
              <BioContentful document={personBio} />
            </Text>
          </BioWrapper>
        )}
      </MainWrapper>
    </Modal>
  );
};

export default TestimonialPopup;
