import * as React from "react";
import { Grid } from "@mui/material";
import styled from "styled-components";
import { Flex } from "../../components/Box";
import { ITeamFields } from "../../components/SubpageOurTeam/types";
import { Text } from "../../components/Text";
import { DepartmentName } from "../../utils/getDepartmentColor";
import PersonTile from "../../components/SubpageOurTeam/PersonTile";
import getContentfulData from "../../utils/getContentfulData";
import { useEffect, useState } from "react";
import useAboutUsPopup from "../../components/SubpageOurTeam/AboutUsPopup.tsx/useAboutUsPopup";
import PartnersSlider from "../../components/partnersSlider";

const StyledFlexBox = styled(Flex)`
  width: 100%;
  max-width: 1215px;
  flex-direction: column;
  margin: 0 auto;
  padding: 0 32px;
`;

const MainTeamWrapper = styled(Flex)`
  flex-direction: column;
  gap: 64px;
`;

const StyledGridItem = styled(Grid)`
  display: flex;
  justify-content: center;
`;

interface OurTeamProps {
  teamData: ITeamFields[];
}

const OurTeam: React.FC<OurTeamProps> = ({ teamData }) => {
  const [personData, setPersonData] = useState<ITeamFields[]>([]);
  const { showAboutUsPopup } = useAboutUsPopup({});
  async function getPartnersData() {
    const contentfulData = await getContentfulData("team");
    const allTeamData = contentfulData.items.map((person: any) => {
      return {
        personImage: person.fields?.personImage.fields.file.url,
        personName: person.fields?.personName,
        personPosition: person.fields?.personPosition,
        personBio: person.fields?.personBio || null,
        position: person.fields?.position,
        blockchainKnowledge: person.fields?.blockchainKnowledge || null,
        personSkills: person.fields?.personSkills || null,
        department: person.fields?.department || null,
      };
    });

    return setPersonData(allTeamData);
  }

  useEffect(() => {
    getPartnersData();
  }, []);

  const getSortedUsersByDepartment = (
    departmens: DepartmentName[] | DepartmentName
  ) => {
    return personData
      .filter(({ department }) => {
        const userDepartment: DepartmentName = department
          ? (department[0] as DepartmentName)
          : DepartmentName.OTHER;
        return departmens.includes(userDepartment);
      })
      .sort(
        (currentPosition, previousPosition) =>
          currentPosition.position - previousPosition.position
      );
  };

  const designTeam = [DepartmentName.UXUI, DepartmentName.DESIGN];
  const othersTeam = [DepartmentName.BIZDEV, DepartmentName.ADVISOR];
  const developersTeam = [
    DepartmentName.FRONTEND,
    DepartmentName.BACKEND,
    DepartmentName.UNITY,
    DepartmentName.DELIVERY,
    DepartmentName.QA,
  ];
  return (
    <StyledFlexBox>
      <Text padding="124px 0 84px 0" fontSize="48px" fontWeight="bold">
        Our Team
      </Text>
      <MainTeamWrapper>
        <Grid container lg={12} rowGap={10}>
          {getSortedUsersByDepartment(DepartmentName.BOARD).map(
            (personData, index) => {
              return (
                <StyledGridItem item lg={3} md={4} sm={6} xs={12} key={index}>
                  <PersonTile
                    profileData={personData}
                    showPopup={() => showAboutUsPopup(personData)}
                  />
                </StyledGridItem>
              );
            }
          )}
        </Grid>
        <Grid container lg={12} rowGap={10}>
          {getSortedUsersByDepartment(developersTeam).map(
            (personData, index) => {
              return (
                <StyledGridItem item lg={3} md={4} sm={6} xs={12} key={index}>
                  <PersonTile
                    profileData={personData}
                    showPopup={() => showAboutUsPopup(personData)}
                  />
                </StyledGridItem>
              );
            }
          )}
        </Grid>
        <Grid container lg={12} rowGap={10}>
          {getSortedUsersByDepartment(designTeam).map((personData, index) => {
            return (
              <StyledGridItem item lg={3} md={4} sm={6} xs={12} key={index}>
                <PersonTile
                  profileData={personData}
                  showPopup={() => showAboutUsPopup(personData)}
                />
              </StyledGridItem>
            );
          })}
        </Grid>
        <Grid container lg={12} rowGap={10}>
          {getSortedUsersByDepartment(DepartmentName.MARKETING).map(
            (personData, index) => {
              return (
                <StyledGridItem item lg={3} md={4} sm={6} xs={12} key={index}>
                  <PersonTile
                    profileData={personData}
                    showPopup={() => showAboutUsPopup(personData)}
                  />
                </StyledGridItem>
              );
            }
          )}
        </Grid>
        <Grid container lg={12} rowGap={10}>
          {getSortedUsersByDepartment(othersTeam).map((personData, index) => {
            return (
              <StyledGridItem item lg={3} md={4} sm={6} xs={12} key={index}>
                <PersonTile
                  profileData={personData}
                  showPopup={() => showAboutUsPopup(personData)}
                />
              </StyledGridItem>
            );
          })}
        </Grid>
      </MainTeamWrapper>
      <PartnersSlider />
    </StyledFlexBox>
  );
};

export default OurTeam;
