import React from "react";
import styled from "styled-components";
import { X } from "react-feather";
import { InjectedProps } from "./types";

interface Props extends InjectedProps {
  children: React.ReactNode;
}

const StyledModal = styled.div`
  width: max-content;
  position: relative;
  background: #141e2f;
  border-radius: 20px;
  z-index: 10;
  padding-bottom: 64px;
  @media (max-width: 960px) {
    width: 80vw;
    padding-bottom: 32px;
  }
`;

const CloseButton = styled.div`
  display: flex;
  cursor: pointer;
  color: #fff;
  z-index: 100;
`;

const ButtonPosition = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 32px 32px 0;
`;

const Modal: React.FC<Props> = ({ onDismiss, children }) => (
  <StyledModal>
    <ButtonPosition>
      <CloseButton onClick={onDismiss}>
        <X />
      </CloseButton>
    </ButtonPosition>
    {children}
  </StyledModal>
);

export default Modal;
