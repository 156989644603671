import * as React from "react";
import { ArrowRight } from "react-feather";
import styled from "styled-components";
import SkillStar from "../../svg/SkillStar";
import { getDepartmentColor } from "../../utils/getDepartmentColor";
import BgImage from "../../images/AboutUsTileBackground.png";
import Flex from "../Box/Flex";
import { Text } from "../Text";
import { ITeamFields } from "./types";

const StyledTile = styled(Flex)`
  position: relative;
  width: 280px;
  height: 410px;
  padding: 14px;
  flex-direction: column;
  align-items: center;
  border-radius: 30px;
  background: rgba(20, 30, 47, 0.7);
  border: 1px solid #3a527e;
`;

const ImageBackground = styled(Flex)`
  width: 100%;
  height: 145px;
  position: relative;
  justify-content: center;
`;

const ImageWrapper = styled(Flex)`
  position: absolute;
  bottom: 0;
`;

const NameWrapper = styled(Flex)`
  position: absolute;
  bottom: -10%;
  justify-content: center;
  align-items: center;
  background: rgba(16, 23, 35, 0.4);
  box-shadow: inset 0px 0px 5px rgba(16, 23, 35, 0.5);
  border-top: 1px solid #405577;
  backdrop-filter: blur(50px);
  border-radius: 30px;
  width: auto;
  padding: 10px 16px;
  height: 41px;
`;

const PositionWrapper = styled(Flex)`
  width: 100%;
  max-width: 225px;
  min-height: 40px;
  justify-content: center;
  padding-bottom: 14px;
  margin-top: 24px;
  text-align: center;
  border-bottom: 1px solid #273754;
`;

const SkillsWrapper = styled(Flex)`
  width: 100%;
  max-width: 225px;
  min-height: 110px;
  padding: 24px 0;
  border-bottom: 1px solid #273754;
`;

const StyledRow = styled(Flex)`
  flex-direction: column;
  min-width: 122px;
  gap: 9px;
`;

const KnowledgeWrapper = styled(Flex)`
  width: 100%;
  padding: 10px 14px;
  flex-direction: column;
  justify-content: flex-start;
`;

const StarsWrapper = styled(Flex)`
  gap: 8px;
`;

const ArrowWrapper = styled(Flex)`
  position: absolute;
  width: 48px;
  height: 48px;
  bottom: 10px;
  right: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #141e2f;
  border: 1px solid #3a527e;
  cursor: pointer;
  :hover {
    border: 1px solid #2d3f62;
  }
`;

const Department = styled(Flex)`
  position: absolute;
  left: 0;
  top: -10px;
`;

const DepartmentBadge = styled(Flex)<{ backgroundColor: string }>`
  width: 100%;
  height: 31px;
  padding: 8px 12px;
  border-radius: 30px;
  background: ${({ backgroundColor }) => backgroundColor};
  justify-content: center;
  align-items: center;
`;

const StyledText = styled.div`
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
  color: #fff;
  letter-spacing: 0.05em;
`;

export interface PersonTileProps {
  showPopup?: () => void;
  profileData: ITeamFields;
}

const PersonTile: React.FunctionComponent<PersonTileProps> = ({
  profileData,
  showPopup,
}) => {
  const {
    blockchainKnowledge,
    personImage,
    personName,
    personPosition,
    personSkills,
    department,
  } = profileData;

  const starRating = Array.from(Array(blockchainKnowledge), (el) => "rating");
  const defaultStars = Array.from(Array(3).keys());

  return (
    <StyledTile>
      <Department>
        {department?.map((value, index) => {
          return (
            <DepartmentBadge
              backgroundColor={getDepartmentColor(value)}
              key={index}
            >
              <StyledText>{department}</StyledText>
            </DepartmentBadge>
          );
        })}
      </Department>
      <ImageBackground>
        <img src={BgImage} />
        <ImageWrapper>
          <img src={`https://${personImage}`} alt="PersonImage" />
        </ImageWrapper>
        <NameWrapper>
          <Text fontSize="18px" color="#fff">
            {personName}
          </Text>
        </NameWrapper>
      </ImageBackground>
      <PositionWrapper>
        <Text color="#A2B3CD" fontSize="14px" lineHeight="115%">
          {personPosition}
        </Text>
      </PositionWrapper>
      <SkillsWrapper>
        <StyledRow>
          {personSkills?.slice(0, 3).map((skill: string) => {
            return (
              <Text
                key={skill}
                lineHeight="115%"
                fontSize="12px"
                color="#95A8C6"
              >
                {skill}
              </Text>
            );
          })}
        </StyledRow>
        <StyledRow>
          {personSkills?.slice(3, 6).map((skill: string) => {
            return (
              <Text
                key={skill}
                lineHeight="115%"
                fontSize="12px"
                color="#95A8C6"
              >
                {skill}
              </Text>
            );
          })}
        </StyledRow>
      </SkillsWrapper>
      <KnowledgeWrapper>
        <Text fontSize="12px" color="#95A8C6">
          Blockchain
        </Text>
        <StarsWrapper>
          {defaultStars.map((index) => {
            return (
              <SkillStar
                fill={starRating[index] ? "#7763F6" : "none"}
                key={index}
              />
            );
          })}
        </StarsWrapper>
      </KnowledgeWrapper>
      <ArrowWrapper onClick={showPopup}>
        <ArrowRight color="#E5EAF0" />
      </ArrowWrapper>
    </StyledTile>
  );
};

export default PersonTile;
