import * as React from "react";
import { BLOCKS, Document } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import styled from "styled-components";

export interface IRichContentfulProps {
  document: any;
}

const StyledText = styled.div<{
  color?: string;
  fontSize?: string;
  bold?: boolean;
}>`
  line-height: 31px;
  font-size: 18px;
  color: ${({ color }) => color};
  font-weight: ${({ bold }) => (bold ? "bold" : "normal")};
  white-space: pre-line !important;
`;

const StyledListItem = styled.li`
  margin-left: 24px;
  line-height: 31px;
  margin-bottom: 16px;
  white-space: pre-line !important;
`;

const BioContentful: React.FC<IRichContentfulProps> = ({ document }) => {
  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (_node: any, children: any) => {
        return <StyledText color="#e6e6e6">{children}</StyledText>;
      },
      [BLOCKS.HEADING_1]: (_node: any, children: any) => (
        <StyledText color="#fff" bold>
          {children}
        </StyledText>
      ),
      [BLOCKS.LIST_ITEM]: (_node: any, children: any) => {
        return <StyledListItem>{children}</StyledListItem>;
      },
    },
  };
  return <>{documentToReactComponents(document, options)}</>;
};

export default BioContentful;
